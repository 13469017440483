/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";

import { AppContext } from "~context/AppContext";

import Banner from "~components/Banner";
import FormFooter from "~components/FormFooter";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

class ShippingPageComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;

    appContext.setMenuActive(false);
    appContext.setNavText(`Back`);
  }

  //

  render() {
    const { bannerMedia, faqs } = this.props.data.markdownRemark.frontmatter;

    return (
      <>
        <SEO pageTitle="Shipping" pathName="/shipping" />

        <Layout className="shipping-page w-full bg-sun-yellow gpu">
          <Banner
            bannerImage={bannerMedia.src.childImageSharp.fluid}
            bannerText={bannerMedia.bannerText}
          />

          <section
            className="animation-appear relative px-6 xs:px-3 pt-4 pb-24"
            style={{ animationDelay: `200ms` }}
          >
            {faqs.map((faq, index) => {
              const faqKey = `faq-${index}`;
              return (
                <div className="grid" key={faqKey}>
                  <p className="w-3/4 xs:w-full mt-12 f4 font-medium text-black grid-end-3 sm:grid-end-12">
                    {faq.question}
                  </p>

                  <p
                    className="w-3/5 xs:w-full mt-12 f4 font-medium text-black grid-end-9 sm:grid-end-12"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  ></p>
                </div>
              );
            })}
          </section>
          <FormFooter headlineText="Headline. Goes here." />
        </Layout>
      </>
    );
  }
}

const ShippingPage = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <ShippingPageComponent appContext={appContext} {...props} />
      )}
    </AppContext.Consumer>
  );
};

export default ShippingPage;

export const query = graphql`
  query ShippingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        bannerMedia {
          bannerText
          src {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                src
              }
            }
          }
        }
        faqs {
          question
          answer
        }
      }
    }
  }
`;
